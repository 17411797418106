import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationLoading } from '@mediabank/uikit-v5';

import userSlice, { validateToken } from './store/user';

const AuthWrapper = ({ children }) => {
    const dispatch = useDispatch();

    const { token, loading, authenticated } = useSelector(userSlice.selectors.getUser);

    if (authenticated) {
        // Logged in
        return children;
    } else if (token) {
        // Authentication in progress
        if (!loading && !authenticated) {
            dispatch(validateToken({ token }));
        }

        return <ApplicationLoading />;
    } else {
        // Render non-protected routes (and login)
        return children;
    }
};

export default AuthWrapper;
