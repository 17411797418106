import React from 'react';
import ReactDOM from 'react-dom';
import { sentryInit } from '@mediabank/utils';

import App from './App';
import { SENTRY_DSN, SENTRY_ENVIRONMENT, SENTRY_IS_ACTIVE, SENTRY_RELEASE } from './constants';
import * as serviceWorker from './serviceWorker';

if (SENTRY_IS_ACTIVE) {
    // Sentry initialization
    sentryInit({
        dsn: SENTRY_DSN,
        environment: SENTRY_ENVIRONMENT,
        release: SENTRY_RELEASE,
    });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
