export const mockPropertiesTabResponse = {
    links: {
        self: '/api/v1/assets/1137/properties',
    },
    data: [
        {
            type: 'asset/properties',
            id: '1137/format',
            links: {
                self: '/api/v1/assets/1137/format',
            },
            attributes: {
                name: 'Media format',
            },
            meta: {
                openOnLoad: true,
            },
        },
        {
            type: 'asset/forms',
            id: '1137/meta_data',
            links: {
                related: '/api/v1/assets/1137/forms/meta_data',
            },
            attributes: {
                name: 'Properties',
            },
            meta: {
                openOnLoad: false,
            },
        },
        {
            type: 'asset/properties',
            id: '1137/readonlymetafields',
            links: {
                related: '/api/v1/assets/1137/readonlymetafields',
            },
            attributes: {
                name: 'Read-only fields',
            },
            meta: {
                openOnLoad: false,
            },
        },
        {
            type: 'asset/properties',
            id: '1137/status',
            links: {
                related: '/api/v1/assets/1137/status',
            },
            attributes: {
                name: 'Current status',
            },
            meta: {
                openOnLoad: false,
            },
        },
        {
            type: 'asset/logs',
            id: '1137',
            links: {
                related: '/api/v1/assets/1137/log',
            },
            attributes: {
                name: 'Log',
            },
            meta: {
                openOnLoad: false,
            },
        },
        {
            type: 'asset/acl',
            id: '1137',
            links: {
                related: '/api/v1/assets/1137/acl',
            },
            attributes: {
                name: 'Access',
            },
            meta: {
                openOnLoad: false,
            },
        },
        {
            type: 'asset/audio',
            id: '1137',
            links: {
                related: '/api/v1/assets/1137/audio',
            },
            attributes: {
                name: 'Audio',
            },
            meta: {
                openOnLoad: false,
            },
        },
        {
            type: 'asset/raceinfo',
            id: '1137',
            links: {
                related: '/api/v1/assets/1137/raceinfo',
            },
            attributes: {
                name: 'Race Info',
            },
            meta: {
                openOnLoad: false,
            },
        },
    ],
};
