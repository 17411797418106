import { createServer } from 'miragejs';

import { mockEventsResponse } from './fixtures/events';
import { mockPropertiesTabResponse } from './fixtures/propertiesTab';

// All paths defined here will be handled by mirage server
const mockEndpoints = [
    //    /.api\/v1\/tags\/events/,
    //    /.api\/v1\/tags\/assets\/lookup/
];

const createMockServer = ({ environment = 'development' }) => {
    createServer({
        environment,
        routes() {
            this.urlPrefix = process.env.REACT_APP_API_URL;
            this.logging = environment === 'development' ? true : false;

            this.get('/api/v1/assets/:id/properties', () => mockPropertiesTabResponse);
            this.get('/api/v1/tags/assets/lookup', (schema, request) => {
                const { queryParams } = request;

                const response = {};

                const keys = Object.keys(queryParams);

                keys.forEach((key, index) => {
                    const isHappeningdId = key.includes('happeningId');

                    if (isHappeningdId) {
                        const happeningId = queryParams[key];
                        response[happeningId] = {};
                    } else {
                        const part = queryParams[key];

                        const prevKey = keys[index - 1];
                        const prevHappeningId = queryParams[prevKey];
                        response[prevHappeningId][part] = prevHappeningId === '45088' ? assets[0] : assets[1];
                    }
                });

                return response;
            });
            this.get('/api/v1/tags/events', (schema, request) => {
                const {
                    queryParams: { page, limit, sortOrder },
                } = request;
                if (sortOrder === 'asc') {
                    mockEventsResponse.data.events.reverse();
                } else {
                    mockEventsResponse.data.events.sort();
                }
                const response = JSON.parse(JSON.stringify(mockEventsResponse));
                const noItems = mockEventsResponse.data.events.length;
                const parsedPage = parseInt(page, 10);
                const parsedLimit = parseInt(limit, 10);
                const start = (parsedPage - 1) * parsedLimit;
                const end = parsedPage * parsedLimit;

                response.data.events = response.data.events.slice(start, end);

                response.data.meta = { noItems };

                return { ...response };
            });

            this.passthrough(request => {
                const found = mockEndpoints?.filter(path => request.url.match(new RegExp(path)));

                return !found.length;
            });
        },
    });
};

var assets = [
    {
        type: 'assets',
        id: '16567',
        attributes: {
            assetid: 16567,
            assettitle: 'Tags Test Asset',
            assetcompany_id: '3006',
            assetfilename: '16567_original.mxf',
            assetingested: '2022-05-11T10:21:20.10829+02',
            assetcreated: '2022-05-11T10:21:20.10829+02',
            assetmodified: '2022-09-28T10:28:37.020677+02',
            assetaccessed: null,
            assettype_id: null,
            assetsize: '974032200',
            assetingestperson_id: '127',
            assetintro: null,
            assetimage: null,
            assettext: null,
            assetkeywords: null,
            assetstoragelocation: null,
            assetuploadid: null,
            assetuploadhost: null,
            assetoriginaldata: null,
            assetmeta: {
                Part: '',
                Arena: 'Small',
                Title: 'Tags Test Asset',
                Venue: 'Charles Schwab Field',
                Season: '2022',
                Status: 'new',
                Context: 'Competition',
                NCAADay: '',
                XMLType: '',
                Category: 'Futsal',
                ClipTest: '{"start":"00:00:00:00","end":"00:00:00:00"}',
                Filename: '3347_match_part_2_LiveIngest_ready_VC-3.mxf',
                Filesize: '974032200',
                MimeType: 'video',
                MiscType: '',
                NCAAGame: '',
                SubTitle: '[No text]',
                TagsDate: '2021-02-12',
                TagsType: 'sport',
                Copyright: '100% UEFA, not including commentary',
                EventDate: '',
                EventWeek: '',
                Evergreen: 'Single Event',
                MBContext: 'UEFA',
                MediaType: 'Video',
                NCAARound: '',
                TrackerId: '2017287',
                AudioCodec: 'AAC',
                FileStatus: 'ready',
                SportsName: "Men's Soccer",
                UploadHost: 'https://dev.mediabank.me/',
                VideoWidth: '960',
                Application: 'library',
                AudioFormat: 'AAC',
                AudioTracks: '2',
                Competition: 'European Futsal Championship 2022',
                ElementType: '',
                GameSupport: 'Yes',
                Sensitivity: 'None',
                TagsEventId: '',
                TagsSubType: 'futsal',
                TagsVersion: '2',
                VideoFormat: 'AVC',
                VideoHeight: '540',
                AudioMapping: '-ac 2',
                EventRelated: 'true',
                EventWeekDay: 'Tuesday',
                IngestSource: 'EVENTCONTENTUPLOAD_ESPNLATINA',
                MatchRelated: 'false',
                MimeTypeFull: 'video/mxf',
                NCAAAwayTeam: 'Lipscomb',
                NCAACategory: 'Big 12',
                NCAAHomeTeam: 'Kentucky',
                ReviewStatus: 'new',
                SegmentTitle: '',
                TagsDomainId: '1198',
                UnBlockParts:
                    '{"parts":[{"start":"00:02:50:00","end":"00:12:16:19"},{"start":"00:39:29:00","end":"00:48:23:24"}]}',
                UploadFormId: '4393',
                VideoBitRate: '1500160',
                CompetitionId: '20231',
                ContentOrigin: 'ESPN',
                FilesizeProxy: '709924890',
                NCAACopyright: '100% ESPN, including audio',
                RecordingType: 'Host broadcast',
                VideoLanguage: '',
                VideoScanType: 'Progressive',
                EventStartTime: '7:30 PM',
                PartnerEventId: '0d164039-0839-32ef-84ae-7bbab3f97ea9',
                ShareToCompany: '879004,900004,908004,893004,894004,895004,901004,903004,898004,899004',
                SubSegmentType: '',
                UploadUserName: 'markus',
                VideoFrameRate: '25.000',
                AssetCollection: 'European Futsal Championship',
                AudioResolution: '',
                AudioTemplateId: '5660010',
                GeneralDuration: '4500032',
                GeneralFileSize: '974032200',
                HappeningLookup: '',
                NCAAContentType: 'Feature',
                NCAASegmentType: 'Final',
                NumberOfPosters: '91',
                RosterSheetType: '',
                TagsHappeningId: '98764',
                UploadUserEmail: 'msundin@nepgroup.com',
                UploadUserPhone: '46722034968',
                CompetitionShort: 'FEURO 2022',
                VideoAspectRatio: '16:9',
                AssetCollectionId: '5',
                AudioSamplingRate: '48000',
                CanalDigitalGenre: 'Option1',
                OriginalEventDate: '9/20/2022',
                PublicationStatus: 'Published',
                UploadCompanyName: 'NEP Media Services AS',
                VideoHeightOffset: '',
                UploadUserFullName: 'Markus Sundin',
                VideoFormatProfile: 'Main@L3.1',
                VideoOriginalWidth: '',
                CanalDigitalValidTo: '',
                FileStorageLocation: 'NETWERK/202205/16567',
                VideoCommercialName: 'AVC',
                VideoOriginalHeight: '',
                AssetCollectionShort: 'FEURO',
                CanalDigital169Thumb:
                    'hasnewthumb=no,x=206,y=42,width=585,height=329,tc=00:00:00:00,thumburl=https://static-eu1.mediabank.me/b/NETWERK/202205/16567/16567_canal_digital_169.png',
                CanalDigital916Thumb:
                    'hasnewthumb=no,x=320,y=0,width=320,height=540,proxyWidth=960,proxyHeight=540,tc=00:00:00:00,thumburl=',
                CanalDigitalShowType: 'Series',
                GeneralFileExtension: 'mxf',
                GeneralFormatProfile: 'Base Media / Version 2',
                GeneralTimeCodeStart: '0',
                PlayerStartTimestamp: '1559427055',
                CanalDigitalValidFrom: '',
                GeneralOverallBitRate: '1731600',
                MbNext2ndFieldsetText: 'This is a random value: 2adb0d21d1f5732ecd72427b493eb269',
                NCAAPublicationStatus: 'Share to Category',
                VideoOriginalScanType: '',
                VideoWritingApplication: '',
                CanalDigitalSeasonNumber: '',
                ReviewNotificationListId: null,
                CanalDigitalEpisodeNumber: '',
                CanalDigitalNoAudioFormat: 'stereo',
                CanalDigitalNoSeasonTitle: '',
                CanalDigitalNoSeriesTitle: '',
                GeneralOverallBitRateMode: 'VBR',
                AudioChannelsInFirstStream: '2',
                AutoProcessedDeliveryRules: '1',
                CanalDigitalNoEpisodeTitle: 'Tags Test Asset',
                CanalDigitalProductionYear: '',
                VideoEncodedApplicationName: '',
                CanalDigitalNoParentalRating: '0',
                CanalDigitalNumberOfEpisodes: '',
                CanalDigitalNoLongDescription: '',
                CanalDigitalProductionCountry: '',
                CanalDigitalNoShortDescription: '',
                VideoEncodedApplicationVersion: '',
                VideoEncodedApplicationCompanyName: '',
                DurationTC: '01:15:00:01',
                assetid: 16567,
                assettitle: 'Tags Test Asset',
                assetcompany_id: '3006',
                assetfilename: '16567_original.mxf',
                assetingested: '2022-05-11T10:21:20.10829+02',
                assetcreated: '2022-05-11T10:21:20.10829+02',
                assetmodified: '2022-09-28T10:28:37.020677+02',
                assetaccessed: null,
                assettype_id: null,
                assetsize: '974032200',
                assetingestperson_id: '127',
                assetintro: '[No text]',
                assetimage: null,
                assettext: null,
                assetkeywords: null,
                assetstoragelocation: null,
                assetuploadid: null,
                assetuploadhost: null,
                assetoriginaldata: null,
                assetlinkto_id: null,
                assethasoriginal: '1',
                assetlocked: '0',
                assetbackupstatus: '0',
                assetarchivestatus: '0',
                assetonlinestatus: true,
                assetparent_id: 0,
                assetduration: '4500032',
                Image: 'https://static-eu1.mediabank.me/b/NETWERK/202205/16567/16567_poster.png?v=202209281028',
                PosterURL: 'https://static-eu1.mediabank.me/b/NETWERK/202205/16567/16567_poster.png?v=202209281028',
                IsMirror: 'no',
                Id: 16567,
            },
            assetlinkto_id: null,
            assethasoriginal: '1',
            assetstoragelocations: {
                Online: 'NewOnline',
                OnlineStatus: '1',
            },
            assetlocked: '0',
            assetbackupstatus: '0',
            assetarchivestatus: '0',
            assetonlinestatus: null,
            assetparent_id: 0,
            assetduration: '4500032',
            map_data: {
                assetid: 16567,
                ThumbUrl: 'https://static-eu1.mediabank.me/b/NETWERK/202205/16567/16567_poster.png?v=202209281028',
                Image: 'https://static-eu1.mediabank.me/b/NETWERK/202205/16567/16567_poster.png?v=202209281028',
                Title: 'Tags Test Asset',
                Intro: '[No text]',
                IsMirrored: 'no',
                ACL: {
                    aclgids: ['7', '8', '9'],
                    acluids: ['127'],
                    aclcompany_id: ['3006'],
                },
                size: '928.9MB',
                proxysize: '677.0MB',
                duration: '01:15:00:01',
                bitrate: '1.7MBbps',
                videobitrate: '1.4MBbps',
                tcstart: '00:00:00:00',
            },
            ProgressiveURL: '/api/v1/assets/16567/media/proxy_play',
            DownloadOriginalURL: '/api/v1/assets/16567/media/original',
            DownloadProxyURL: '/api/v1/assets/16567/media/proxy',
        },
        links: {
            self: '/api/v1/assets/16567',
        },
    },
    {
        type: 'assets',
        id: '6186',
        attributes: {
            assetid: 6186,
            assettitle: 'Markus Tags Testfile',
            assetcompany_id: '3006',
            assetfilename: '6186_original.mov',
            assetingested: '2018-02-22T08:47:52.558154+01',
            assetcreated: '2018-02-22T08:47:52.558154+01',
            assetmodified: '2020-03-17T15:03:16.847153+01',
            assetaccessed: null,
            assettype_id: null,
            assetsize: '853546643',
            assetingestperson_id: '127',
            assetintro: null,
            assetimage: null,
            assettext: null,
            assetkeywords: null,
            assetstoragelocation: null,
            assetuploadid: null,
            assetuploadhost: null,
            assetoriginaldata: null,
            assetmeta: {
                Title: 'New title',
                Season: '',
                Status: 'new',
                Episode: '',
                MediaID: '',
                Category: "Markus's Test",
                ClipType: '-1',
                Comments: '',
                Filename: 'soccer_match.mov',
                Filesize: '853546643',
                MimeType: 'video',
                SubTitle: 'Test<script>alert(1)</script>',
                TagsType: 'sport',
                OnAirDate: '',
                TrackerId: '2016486',
                AudioCodec: 'AAC LC',
                DplayTitle: "Markus Tags Testfile Episode  (Markus's Test)",
                FileStatus: 'ready',
                VideoWidth: '960',
                Application: 'library',
                AudioFormat: 'AAC',
                AudioTracks: '2',
                DplayRating: 'Everyone',
                TagsSubType: 'speed-skating',
                VideoFormat: 'AVC',
                VideoHeight: '540',
                AudioMapping: '-ac 2',
                OnAirChannel: '',
                ReviewStatus: 'new',
                TagsDomainId: '1378',
                UploadFormId: '4316',
                VideoBitRate: '1500160',
                DateFieldTest: '2018-02-22',
                Dplay169Thumb: 'hasnewthumb=no,x=0,y=0,width=0,height=0,tc=00:00:00:00,thumburl=',
                DplayGeoBlock: 'Norway',
                FilesizeProxy: '670235193',
                VideoScanType: 'Progressive',
                UploadUserName: 'markus',
                VideoFrameRate: '25.000',
                AudioResolution: '',
                DplayRequireDRM: 'false',
                GeneralDuration: '3900032',
                GeneralFileSize: '853546643',
                NumberOfPosters: '88',
                TagsHappeningId: '29320',
                UploadUserEmail: 'markus@netwerk.no',
                UploadUserPhone: '47295958',
                DplayPickerTest3: '2016-08-18 11:00',
                VideoAspectRatio: '16:9',
                AudioSamplingRate: '48000',
                DateTimeFieldTest: '2018-02-22 07:00:00 UTC',
                UploadCompanyName: 'NEP Media Services AS',
                VideoHeightOffset: '',
                DplayProgramSeries: '-1',
                UploadUserFullName: 'Markus Sundin',
                VideoFormatProfile: 'Main@L3.1',
                VideoOriginalWidth: '',
                FileStorageLocation: 'NETWERK/201802/6186',
                VideoCommercialName: 'AVC',
                VideoOriginalHeight: '',
                GeneralFileExtension: 'mov',
                GeneralFormatProfile: 'Base Media / Version 2',
                GeneralTimeCodeStart: '0',
                PlayerStartTimestamp: '1559427005',
                DplayShortDescription: '',
                GeneralOverallBitRate: '1750850',
                VideoOriginalScanType: '',
                VideoWritingApplication: '',
                ReviewNotificationListId: '-1',
                DatepickerTimeTestDynamic: '',
                GeneralOverallBitRateMode: 'VBR',
                AudioChannelsInFirstStream: '2',
                ConditionalFirstStreamIsSet: '',
                VideoEncodedApplicationName: '',
                VideoEncodedApplicationVersion: '',
                VideoEncodedApplicationCompanyName: '',
                DurationTC: '01:05:00:01',
                assetid: 6186,
                assettitle: 'Markus Tags Testfile',
                assetcompany_id: '3006',
                assetfilename: '6186_original.mov',
                assetingested: '2018-02-22T08:47:52.558154+01',
                assetcreated: '2018-02-22T08:47:52.558154+01',
                assetmodified: '2020-03-17T15:03:16.847153+01',
                assetaccessed: null,
                assettype_id: null,
                assetsize: '853546643',
                assetingestperson_id: '127',
                assetintro: '[No text]',
                assetimage: null,
                assettext: null,
                assetkeywords: null,
                assetstoragelocation: null,
                assetuploadid: null,
                assetuploadhost: null,
                assetoriginaldata: null,
                assetlinkto_id: null,
                assethasoriginal: '1',
                assetlocked: '0',
                assetbackupstatus: '0',
                assetarchivestatus: '0',
                assetonlinestatus: true,
                assetparent_id: 0,
                assetduration: '3900032',
                Image: 'https://static-eu1.mediabank.me/d/NETWERK/201802/6186/6186_poster.png?v=202003171503',
                PosterURL: 'https://static-eu1.mediabank.me/d/NETWERK/201802/6186/6186_poster.png?v=202003171503',
                IsMirror: 'no',
                Id: 6186,
            },
            assetlinkto_id: null,
            assethasoriginal: '1',
            assetstoragelocations: {
                Online: 'DigiplexOnline',
                OnlineStatus: '1',
            },
            assetlocked: '0',
            assetbackupstatus: '0',
            assetarchivestatus: '0',
            assetonlinestatus: null,
            assetparent_id: 0,
            assetduration: '3900032',
            map_data: {
                assetid: 6186,
                ThumbUrl: 'https://static-eu1.mediabank.me/d/NETWERK/201802/6186/6186_poster.png?v=202003171503',
                Image: 'https://static-eu1.mediabank.me/d/NETWERK/201802/6186/6186_poster.png?v=202003171503',
                Title: 'Markus Tags Testfile',
                Intro: '[No text]',
                IsMirrored: 'no',
                ACL: {
                    aclgids: ['7', '8', '9'],
                    acluids: ['127'],
                    aclcompany_id: ['3006'],
                },
                size: '814.0MB',
                proxysize: '639.2MB',
                duration: '01:05:00:01',
                bitrate: '1.7MBbps',
                videobitrate: '1.4MBbps',
                tcstart: '00:00:00:00',
            },
            ProgressiveURL: '/api/v1/assets/6186/media/proxy_play',
            DownloadOriginalURL: '/api/v1/assets/6186/media/original',
            DownloadProxyURL: '/api/v1/assets/6186/media/proxy',
        },
        links: {
            self: '/api/v1/assets/6186',
        },
    },
];

export default createMockServer;
