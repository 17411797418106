const mockEvents = [
    {
        pre_roll: 0,
        post_roll: 11,
        event_start: null,
        asset_id: null,
        asset_title: 'Tottenham - Liverpool',
        match_name: 'Tottenham - Liverpool',
        clip_id: 0,
        icon: 'https://static-eu1.mediabank.me/a/resources/icons/sport/soccer/end_of_transmission.png',
        part: 110,
        code: 'end_of_transmission',
        comment: 'End of transmission - Low behind goal far left',
        event_description: 'Shot by Harry Kane (Tottenham)',
        description: null,
        date: '2019-06-01',
        event_type: 'shot',
        event_match_time: '95:58',
        event_real_time: '2019-06-01 22:27:35 UTC',
        event_id: '54021FEB-90B2-4CFA-A43D-0612D61694841',
        event_timestamp: 1559428065,
        happening_id: 45085,
        external_id: 2025486,
        domain_id: 1615,
        display_time: '00:00',
        domain_league_name: 'UEFA Europa League',
        match_date: '2022-10-13 19:00:00 UTC',
    },
    {
        pre_roll: 50,
        post_roll: 150,
        event_start: null,
        asset_id: null,
        asset_title: 'Manchester United - Liverpool',
        match_name: 'Manchester United - Liverpool',
        clip_id: 0,
        icon: 'https://static-eu1.mediabank.me/a/resources/icons/sport/soccer/end_of_transmission.png',
        part: 110,
        code: 'end_of_transmission',
        comment: 'End of transmission - Low behind goal far left',
        event_description: 'Shot by Marcus Rashford (Manchester United)',
        description: null,
        date: '2019-06-01',
        event_type: 'freekick',
        event_match_time: '95:58',
        event_real_time: '2019-06-01 22:27:35 UTC',
        event_id: '54021FEB-90B2-4CFA-A43D-0612D6169484',
        event_timestamp: 1559428065,
        happening_id: 45086,
        external_id: 2025486,
        domain_id: 1615,
        display_time: '00:00',
        domain_league_name: 'UEFA Europa League',
        match_date: '2022-10-14 19:00:00 UTC',
    },
];

export const mockEventsResponse = {
    data: {
        events: [...Array(40).keys()]
            .map((key, index) =>
                mockEvents.map((event, i) => ({
                    ...event,
                    asset_title: `${event.asset_title} (${index * mockEvents.length + (i + 1)})`,
                    match_name: `${event.match_name} (${index * mockEvents.length + (i + 1)})`,
                    event_id: `${event.event_id}-${key}-${index * mockEvents.length + (i + 1)}`,
                    date: `${event.date}:${index * mockEvents.length + (i + 1)}`,
                    happening_id: event.happening_id + index * mockEvents.length + (i + 1),
                    part: event.part + index * mockEvents.length + (i + 1),
                }))
            )
            .flat(),
        hits: 1891,
        aggregations: {
            event_type: {
                free_kick: 379,
                foul: 291,
                shot: 287,
                extraordinary_event: 172,
                var_generic: 136,
                corner: 126,
                end_of_transmission: 96,
                start_pre_match: 96,
                substitution: 75,
                offside: 68,
                yellow_card: 44,
                goal: 36,
                skill: 29,
                period_end: 26,
                period_start: 26,
                penalty: 4,
            },
            match_stadium: {
                Anfield: 873,
                'Estádio do Dragão': 165,
                'Camp Nou': 154,
                'Parc des Princes': 152,
                'Estadio Metropolitano': 147,
                'Stadion Rajko Mitić': 139,
                'Fußball Arena München': 133,
                'Stadio San Paolo': 128,
            },
        },
    },
};
